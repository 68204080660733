<template>
    <div class='CreateAndEdit'>
        <el-card class="box-card">
            <div>
                <i class="el-icon-close cha" @click="$router.go(-1)"></i>
                <div class="clearfix">
                    {{ userId ? '修改' : '新增' }}用户
                </div>
            </div>

            <el-divider></el-divider>
            <div class="formbox">
                <el-form :model="userInfo" :rules="rules" ref="userInfo">
                    <el-form-item label="账号" prop="account" :label-width="formLabelWidth">
                        <el-input v-model="userInfo.account" :readonly="state"></el-input>
                    </el-form-item>
                    <el-form-item label="真实姓名" prop="realName" :label-width="formLabelWidth">
                        <el-input v-model="userInfo.realName"></el-input>
                    </el-form-item>
                    <el-form-item label="角色" prop="roleId" :label-width="formLabelWidth">
                        <!-- <el-input v-model="formInline.account" placeholder="请输入账号查询"></el-input> -->
                        <el-select placeholder="请选择角色名称" v-model="userInfo.roleId">
                            <el-option v-for="(role, index) in roleList" :key="index" :label="role.roleName"
                                :value="role.roleId"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="联系方式" prop="mobile" :label-width="formLabelWidth">
                        <el-input v-model="userInfo.mobile"></el-input>
                    </el-form-item>
                    <el-form-item label="邮箱" prop="email" :label-width="formLabelWidth">
                        <el-input v-model="userInfo.email"></el-input>
                    </el-form-item>
                    <el-form-item label="用户状态" prop="userStatus" :label-width="formLabelWidth">
                        <el-radio v-model="userInfo.userStatus" :label=0>停用</el-radio>
                        <el-radio v-model="userInfo.userStatus" :label=1>启用</el-radio>
                    </el-form-item>
                    <el-form-item label="备注" prop="remark" :label-width="formLabelWidth">
                        <el-input v-model="userInfo.remark"></el-input>
                    </el-form-item>
                    <el-form-item class="df" :label-width="formLabelWidth">
                        <el-button class="btn" :disabled="submitFlag" @click="submitForm()">提交</el-button>
                        <el-button @click="handleReset">重置</el-button>
                    </el-form-item>
                </el-form>

            </div>

        </el-card>
    </div>
</template>
<script>
import { nameRule } from '@/utils/vaildate.js'
import { getUserById, addUser, updateUser } from '@/api/user'  //, addUser, updateUser
import { queryRoleAll } from '@/api/role'
export default {
    props: {
        userId: {
            type: [String, Number]
        }
    },
    data() {
        return {
            roleList: [],
            userInfo: {
                account: '',
                realName: '',
                roleId: '',
                mobile: '',
                email: '',
                userStatus: 1,
                remark: ''
            },
            formLabelWidth: '80px',
            rules: {
                account: [{ validator: nameRule, trigger: 'blur', required: true }],
                // account: [{required: true, message: '请输入账号'}],
                realName: [{ required: true, message: '请输入姓名' }],
                roleId: [{ required: true, message: '请选择角色' }],
                userStatus: [{ required: true }],
                email: [
                    // eslint-disable-next-line no-useless-escape
                    { pattern: /^([0-9A-Za-z\-_\.]+)@([0-9a-z]+\.[a-z]{2,3}(\.[a-z]{2})?)$/g, message: '请输入正确的邮箱', trigger: 'blur' }
                ]
            },
            submitFlag: false,// 是否提交中,
            state: this.userId ? true : false

        }
    },
    mounted() {
        this.userId && this.loadUserInfo()
        this.loadSelect()
    },
    methods: {
        async loadSelect() {
            await queryRoleAll().then(res => {
                if (res.code === '000000') {
                    this.roleList = res.data
                }
            })
        },
        loadUserInfo() {
            getUserById(this.userId).then(res => {
                if (res.code === '000000') {
                    this.userInfo = res.data
                }
            })
        },
        submitForm() {
            this.$refs.userInfo.validate((valid) => {
                if (valid) {
                    this.submitFlag = true
                    if (this.userId) {
                        updateUser(this.userInfo).then(res => {
                            if (res.code === '000000') {
                                this.$message.success('修改成功')
                                setTimeout(() => {
                                    this.$router.go(-1)
                                }, 1000)
                            }
                            this.submitFlag = false
                        })
                    } else {
                        addUser(this.userInfo).then(res => {
                            if (res.code === '000000') {
                                this.$message.success('添加成功')
                                setTimeout(() => {
                                    this.$router.go(-1)
                                }, 1000)
                            }
                            this.submitFlag = false
                        })
                    }
                } else {
                    return false
                }
            })
        },
        handleReset() {
            this.$refs.userInfo.resetFields()
        }
    }
}

</script>

<style lang="scss" scoped>
.CreateAndEdit {
    .el-card {
        position: relative;
        text-align: left;

        .cha {
            position: absolute;
            top: 5px;
            right: 5px;
            font-size: 30px;
            color: rgba(54, 55, 56, 0.445);
        }

        .cha:hover {
            cursor: pointer;
            color: #2a2d2f;
        }
    }

    .el-form-item {
        width: 500px;
    }

    .df {
        text-align: left;
    }

}
</style>